// Vue
import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router.js'
Vue.config.productionTip = false
// Firebase
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
firebase.initializeApp({
  apiKey: "AIzaSyAjnlKiv2gJJR36oK87lN3YkDwYZcW1eMs",
  authDomain: "clgmbh-face2face.firebaseapp.com",
  databaseURL: "https://clgmbh-face2face.firebaseio.com",
  projectId: "clgmbh-face2face",
  storageBucket: "clgmbh-face2face.appspot.com",
  messagingSenderId: "45776389790",
  appId: "1:45776389790:web:a25f0fb488793a0297dd8f"
})
Vue.use(BootstrapVue)
firebase.auth().signInAnonymously().catch(error => console.error(error)) // eslint-disable-line no-console
firebase.auth().onAuthStateChanged(user => {
  let app = ''
  if (user && !app) {
    app = new Vue({
      render: h => h(App),
      router,
      beforeMount() {
        Vue.prototype.$baseUrl = 'https://face2face.comline.gmbh/#/'
        Vue.prototype.$firestore = firebase.firestore()
      },
    }).$mount('#app')
  }
})
