<template>
  <form id="app" @submit.prevent="send()" novalidate>
    <div class="mb-5">
      <p class="form-text text-muted float-right">* Pflichtfeld</p>
      <h6 class="font-weight-bold">Teilnahme am ComLine Face2Face 2025 Event</h6>
      <div class="custom-control custom-radio">
        <input type="radio" id="participation-yes-28" name="participation" class="custom-control-input" value="ja-28-9" checked v-model="teilnahme">
        <label class="custom-control-label" for="participation-yes-28">Ja, ich reise am 26. März 2025 an (Check-in ab 16 Uhr) <strong>Begrenztes Kontingent!</strong></label>
      </div>
      <div class="custom-control custom-radio">
        <input type="radio" id="participation-yes-29" name="participation" class="custom-control-input" value="ja-29-9" checked v-model="teilnahme">
        <label class="custom-control-label" for="participation-yes-29">Ja, ich reise am 27. März 2025 an (Check-in bis 12 Uhr)</label>
      </div>
    </div>
    <div class="mb-5">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="salutation">Anrede *</label>
            <select class="custom-select" name="salutation" id="salutation" required v-model="anrede" @change="salutation()" ref="salutation">
              <option disabled selected value="">Bitte auswählen</option>
              <option value="Frau">Frau</option>
              <option value="Herr">Herr</option>
            </select>
            <div class="invalid-feedback">
              Bitte auswählen
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="name">Vorname *</label>
            <input type="text" class="form-control" name="name" id="name" @input="name()" ref="firstname" required v-model="vorname">
            <div class="invalid-feedback">
              Bitte ausfüllen
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="surname">Nachname *</label>
            <input type="text" class="form-control" name="surname" id="surname" @input="surname()" ref="surname" required v-model="nachname">
            <div class="invalid-feedback">
              Bitte ausfüllen
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="email">E-Mail-Adresse *</label>
            <input type="email" class="form-control" name="email" id="email" @input="mail()" ref="mail" required v-model="email">
            <div class="invalid-feedback">
              Bitte ausfüllen
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="company">Unternehmen *</label>
            <input type="text" class="form-control" name="company" id="company" @input="company()" ref="company" required v-model="firma">
            <div class="invalid-feedback">
              Bitte ausfüllen
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="customernumber">Kundennummer *</label>
            <input type="text" class="form-control" name="customernumber" id="customernumber" @input="knummer()" ref="customernumber"  required v-model="kundennummer" >
            <div class="invalid-feedback">
              Bitte ausfüllen
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="comment">Bemerkung</label>
          <textarea class="form-control" name="comment" id="comment" rows="8" v-model="bemerkung"></textarea>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <h4>Sie haben noch Fragen oder Wünsche?</h4>
      <p>
        Dann kontaktieren Sie Katrin Johannsen.
      </p>
      <table>
        <tr>
          <td>E-Mail:</td>
          <td><a href="mailto:face2face@comline-shop.de">face2face@comline-shop.de</a></td>
        </tr>
        <tr>
          <td>Telefon:</td>
          <td><a href="tel:046177303502">0461 77303-502</a></td>
        </tr>
      </table>
    </div>

    <div class="mb-5">
      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" name="privacypolicy" id="privacypolicy" @change="privacy()" ref="privacy" required v-model="datenschutz"/>
          <label class="custom-control-label" for="privacypolicy">Ja, ich akzeptiere die <a href="https://www.comline-shop.de/datenschutz/" target="_blank">Datenschutzbestimmungen</a> *</label>
        </div>
      </div>
      <div class="alert alert-danger"  v-if="isntComplete.length">
        <b>Bitte füllen Sie folgende Felder aus:</b>
        <ul>
          <li v-for="isntCompleted in isntComplete" v-bind:key="isntCompleted.id">{{ isntCompleted }}</li>
        </ul>
      </div> 
      <button type="submit" class="btn btn-primary" id="form-submit" v-if="!isSaved" ><div class="spinner-border spinner-border-sm" id="spinner" v-if="clickedSend"></div> Anmeldung senden</button>
      <div class="alert alert-success"  v-if="isSaved && clickedSend">
        Sie haben sich erfolgreich angemeldet.
      </div> 
      <div class="alert alert-danger"  v-if="isntSaved && clickedSend">
        Es ist ein Fehler aufgetreten.
      </div> 
    </div>
  </form>
</template>

<script>
export default {
  name: 'RegistrationView',
  mounted() {

  },
  data() {
    return{
      teilnahme:"ja-28-9",
      anrede:"",
      vorname:"",
      nachname:"",
      email:"",
      firma:"",
      kundennummer:"",
      bemerkung:"",
      datenschutz:"",
      sendState:"",
      sendSuccess:"",
      clickedSend: false,
      isSaved: false,
      isntSaved: false,
      isntComplete: []
    }
  },
  methods: {
    salutation: function(){
      if (this.anrede === ""){
        this.$refs.salutation.classList.add("is-invalid")
      } else {
        this.$refs.salutation.classList.remove("is-invalid")
        this.deleteEvent("Anrede")
      }
    },
    name: function(){
      if (this.vorname === ""){
        this.$refs.firstname.classList.add("is-invalid")
      } else {
        this.$refs.firstname.classList.remove("is-invalid")
        this.deleteEvent("Vorname")
      }
    },
    surname: function(){
      if (this.nachname === ""){
        this.$refs.surname.classList.add("is-invalid")
      } else {
        this.$refs.surname.classList.remove("is-invalid")
        this.deleteEvent("Nachname")
      }
    },
    mail: function(){
      if (this.email === ""){
        this.$refs.mail.classList.add("is-invalid")
      } else {
        this.$refs.mail.classList.remove("is-invalid")
        this.deleteEvent("E-Mail Adresse")
      }
    },
    company: function(){
      if (this.firma === ""){
        this.$refs.company.classList.add("is-invalid")
      } else {
        this.$refs.company.classList.remove("is-invalid")
        this.deleteEvent("Firma")
      }
    },
    knummer: function(){
      if (this.kundennummer === ""){
        this.$refs.customernumber.classList.add("is-invalid")
      } else {
        this.$refs.customernumber.classList.remove("is-invalid")
        this.deleteEvent("Kundennummer")
      }
    },
    privacy: function(){
      if (!this.datenschutz){
        this.$refs.privacy.classList.add("is-invalid")
      } else {
        this.$refs.privacy.classList.remove("is-invalid")
        this.deleteEvent("Datenschutz")
      }
    },
    deleteEvent: function(event) {
      this.isntComplete = this.isntComplete.filter(word => word !== event);
    },
    checkInput: function(){
      this.privacy()
      this.salutation()
      this.name()
      this.surname()
      this.mail()
      this.company()
      this.knummer()

     this.isntComplete = []
      if (this.datenschutz !== true){
        this.isntComplete.push("Datenschutz")
      }
      if (this.anrede === ""){
        this.isntComplete.push("Anrede")
      }
      if (this.vorname === ""){
        this.isntComplete.push("Vorname")
      }
      if (this.nachname === ""){
        this.isntComplete.push("Nachname")
      }
      if (this.email === ""){
        this.isntComplete.push("E-Mail Adresse")
        }
      if (this.firma === ""){
        this.isntComplete.push("Firma")
      }
      if (this.kundennummer === ""){
        this.isntComplete.push("Kundennummer")
      } 
      if (!this.isntComplete.length
      && this.sendState !== true
      && this.sendSuccess !== true
      ){
        return true
      }else{
        return false
      }
    },
    send: function(){
      if(this.checkInput()===true){
        this.clickedSend = true
        this.$firestore.collection("registrierung").add({
            teilnahme: this.teilnahme,
            anrede: this.anrede,
            bemerkung: this.bemerkung,
            datenschutz: this.datenschutz,
            email: this.email,
            firma: this.firma,
            kundennummer: this.kundennummer,
            nachname: this.nachname,
            vorname: this.vorname,
            lang: "DE"
          })
        .then(() =>{
          this.isSaved = true
        })
        .catch(error => {
          window.console.error(error)
        })

      }
    }
  }
}
</script>

<style scoped>

#app {
  font-size: 14px; 
  display: flex;
  flex-direction: column;
  align-items: left;
  color: #505153;
}

.btn-primary {
  background-color: rgba(64, 137, 201, 1);
  border-color: transparent;
}
.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

a {
  color: rgba(64, 137, 201, 1);
}
a:hover{
  color: #286090;
}
</style>