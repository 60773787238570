<template>
  <router-view id="app"></router-view>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
* { 
  font-family: 'Noto Sans', sans-serif;
  } 
body {
  padding: 0;
  margin: 0;
  }
</style>